<!--
 * @Author: Dyf
 * @Date: 2023-02-23 18:50:43
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-30 11:42
 * @Descripttion: 课程信息-详情
-->
<style lang="scss" scoped>
.lesson-information {
  @include innerPage(36px 0 48px);
  @include pageTitle(28px);

  .page-title .date {
    margin-right: auto;
    margin-left: 40px;
    font-size: 16px;
    color: #484848;
    display: flex;
    align-items: center;
    padding-top: 4px;

    .class {
      margin-left: 28px;
    }
  }

  .lesson-history {
    width: calc(100% - 30px);
    height: calc(100% - 72px);
    margin-top: 34px;
    box-sizing: border-box;
    padding: 6px 20px 0 14px;
    overflow: hidden;
    overflow-y: auto;

    ::v-deep {
      .el-timeline {
        &-item {
          padding-bottom: 80px;

          &__content {
            max-width: 100%;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      .lesson-detail {
        h4 {
          font-size: 20px;
          margin-top: 28px;
        }

        .teacher {
          height: 56px;
          margin-top: 20px;
          display: flex;
          align-items: center;

          .el-avatar {
            border: 1px solid #fff;
          }

          .el-tag {
            margin-top: 24px;
          }

          &-info {
            color: #484848;
            line-height: 24px;

            margin: 0 50px 0 10px;

            .bold {
              font-size: 16px;
            }
          }
        }

        .prepare {
          &-box {
            width: calc(100% + 12px);
            margin-top: 28px;
            flex-wrap: wrap;
            @include flexBox;
          }

          &-data {
            width: 100%;
            height: calc(100% - 60px);
            box-sizing: border-box;
            padding: 20px 26px;
          }

          &-item {
            width: 372px;
            height: 234px;
            margin-right: 12px;
            border-radius: 10px;
            background: #fff;
            margin-top: 12px;
            overflow: hidden;

            h5 {
              height: 60px;
              line-height: 60px;
              padding: 0 20px;
              box-sizing: border-box;
              color: #474747;
              background: #f6f7fc;
            }

            &:hover {
              transform: scale(1.03);
              box-shadow: 0px 2px 27px 0px rgba($color: #6c4ecb, $alpha: 0.15);
            }

            .courseware {
              display: flex;
              padding-right: 16px;
              position: relative;
              // cursor: pointer;

              .iconfont-color {
                font-size: 36px;
                margin-right: 16px;
                flex-shrink: 0;
                line-height: 40px;
              }

              .courseware-detail {
                flex-grow: 1;
                width: calc(100% - 52px);

                p {
                  line-height: 24px;
                  color: #474747;
                }

                .title {
                  width: 100%;
                  font-size: 18px;
                  color: #303030;
                  line-height: 40px;
                  margin-bottom: 10px;
                }

                .download {
                  display: flex;
                }

                .el-button {
                  margin-left: auto;
                }
              }
            }

            .knowledge,
            .practice {
              padding: 20px 4px 20px 32px;
              position: relative;

              &-list {
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                box-sizing: border-box;
                padding-right: 20px;

                li {
                  height: 38px;
                  margin-bottom: 8px;
                  @include flexBox(space-between);

                  &:last-child {
                    margin-bottom: 0;
                  }

                  .title {
                    font-size: 16px;
                    color: #2e2e2e;
                    display: flex;
                    align-items: center;
                    width: 240px;
                  }

                  p {
                    flex: 1;
                  }

                  span {
                    color: #6340c8;
                    cursor: pointer;
                    flex-shrink: 0;
                    margin-left: 4px;

                    &:hover {
                      transform: scale(1.05);
                    }
                  }
                }
              }
            }

            .homework {
              padding: 0 32px 20px;
              position: relative;

              &-title {
                height: 60px;
                box-sizing: border-box;
                border-bottom: 1px dashed rgba($color: #2e2e2e, $alpha: 0.17);
                @include flexBox;

                p {
                  flex-grow: 1;
                  font-size: 16px;
                  color: #2e2e2e;
                }

                span {
                  color: #6340c8;
                  cursor: pointer;
                  flex-shrink: 0;
                  margin-left: 4px;

                  &:hover {
                    transform: scale(1.05);
                  }
                }
              }

              &-desc {
                width: calc(100% + 20px);
                height: calc(100% - 78px);
                box-sizing: border-box;
                padding-right: 20px;
                overflow: hidden;
                overflow-y: auto;
                margin-top: 18px;
                line-height: 30px;
                color: #2e2e2e;
              }
            }

            .no-data {
              &--empty {
                flex-direction: column;
                @include flexBox(center);

                img {
                  width: 120px;
                }

                p {
                  line-height: 24px;
                  font-size: 12px;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }

    &.no-data {
      border-radius: 10px;
      background: #fff;
    }
  }

  ::v-deep .el-dialog__body {
    width: 100%;
  }

  .dialog {
    &-knowledge {
      width: 432px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 40px 0;
      color: #6b6b6b;

      &--title,
      &--content {
        width: 100%;
        min-height: 46px;
        border-radius: 10px;
        background: #f0f0f0;
        box-sizing: border-box;
        padding: 10px 25px;
        line-height: 26px;
        margin-bottom: 24px;
      }

      &--content {
        min-height: 100px;
        padding: 18px 25px;
      }
    }

    &-question {
      box-sizing: border-box;
      padding: 40px 8px 40px 32px;

      &.detail {
        .dialog-question--head {
          width: 484px;
        }

        .question--data {
          margin-right: 40px;
        }

        .operate-group {
          width: 116px;
          flex-shrink: 0;
          margin-left: 10px;
          text-align: left;
        }

        .question,
        .subtopic {
          &-item {
            width: 100%;
            margin-bottom: 28px;
            @include flexBox;
            align-items: flex-start;

            &:last-child {
              margin-bottom: 0;
            }

            &.combination {
              .combination--title {
                font-size: 16px;
                color: #404040;
                line-height: 24px;
                margin: 18px 0;
              }
            }
          }

          &--num,
          &--type {
            flex-shrink: 0;
            line-height: 24px;
          }

          &--num {
            color: #1f1f1f;
            font-size: 16px;
          }

          &--type {
            height: 24px;
            background: #6340c8;
            border-radius: 12px 0px 12px 12px;
            text-align: center;
            color: #fff;
            margin: 0 8px 0 4px;
            font-size: 12px;
            padding: 0 8px;
          }

          &--data {
            flex-grow: 1;

            h5 {
              line-height: 24px;
              color: #1f1f1f;
              @include flexBox;
              align-items: baseline;

              span {
                text-align: justify;
                margin-right: 10px;
              }

              .el-image {
                flex-shrink: 0;
                margin-top: -7px;
              }
            }
          }

          &--options {
            margin-top: 12px;

            .option {
              line-height: 24px;
              color: #666666;
              @include flexBox;
              align-items: baseline;
              margin: 10px 0;

              .el-image {
                margin-left: 10px;
                flex-shrink: 0;
                margin-top: -4px;
              }
            }
          }

          &--knowledge,
          &--answer {
            width: 100%;
            min-height: 46px;
            border-radius: 10px;
            background: #f0f0f0;
            box-sizing: border-box;
            padding: 10px 25px;
            line-height: 26px;
            text-align: justify;
          }

          &--knowledge {
            margin-top: 18px;
          }

          &--answer {
            margin-top: 14px;
          }
        }

        .subtopic {
          &--num {
            font-size: 14px;
          }

          &--type {
            margin-right: 6px;
          }

          &--title {
            line-height: 24px;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }

          &--options,
          &--knowledge,
          &--answer {
            width: 100%;
          }
        }
      }

      &--head {
        width: 432px;
        margin-left: 18px;
        box-sizing: border-box;
      }

      &--title,
      &--desc {
        width: 100%;
        min-height: 46px;
        border-radius: 10px;
        background: #f0f0f0;
        box-sizing: border-box;
        padding: 10px 25px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      &--desc {
        @include flexBox;
        align-items: flex-start;

        span {
          flex-shrink: 0;
          white-space: pre-wrap;
        }
      }

      &-list {
        margin-top: 28px;
      }

      &--practise {
        max-height: 540px;
        margin-left: -20px;
        box-sizing: border-box;
        padding-top: 8px;
        overflow: hidden;
        overflow-y: auto;
        @include flexBox;
        align-items: baseline;

        .question {
          &--type {
            flex-shrink: 0;
            line-height: 24px;
          }

          &--type {
            height: 24px;
            background: #6340c8;
            border-radius: 12px 0px 12px 12px;
            text-align: center;
            color: #fff;
            margin: 0 8px 0 4px;
            font-size: 12px;
            padding: 0 8px;
          }

          &--data {
            flex-grow: 1;

            h5 {
              line-height: 24px;
              color: #1f1f1f;
              @include flexBox;
              align-items: baseline;

              span {
                text-align: justify;
                margin-right: 10px;
              }

              .el-image {
                flex-shrink: 0;
                margin-top: -7px;
              }
            }
          }

          &--options {
            margin-top: 12px;

            .option {
              line-height: 24px;
              color: #666666;
              @include flexBox;
              align-items: baseline;
              margin: 10px 0;

              .el-image {
                margin-left: 10px;
                flex-shrink: 0;
                margin-top: -4px;
              }
            }
          }

          &--knowledge,
          &--answer {
            width: 100%;
            min-height: 46px;
            border-radius: 10px;
            background: #f0f0f0;
            box-sizing: border-box;
            padding: 10px 25px;
            line-height: 26px;
            text-align: justify;
          }

          &--knowledge {
            margin-top: 18px;
          }

          &--answer {
            margin-top: 14px;
          }
        }
      }
    }

    &-scroll {
      width: calc(100% + 20px);
      max-height: 540px;
      box-sizing: border-box;
      padding-right: 20px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
</style>
<template>
  <section class="lesson-information">
    <div class="page-title">
      <h3>课程信息</h3>
      <p class="date">
        <span>{{ routeParams.day | formatTime("YYYY年M月D日") }}</span>
        <span>星期{{ routeParams.day | formatTime("d") }}</span>
        <span class="class">{{ routeParams.className }}</span>
      </p>
      <el-button type="custom_primary" size="small" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
    <div class="lesson-history" :class="{ 'no-data': $isEmpty(lessonData) }">
      <el-timeline v-show="!$isEmpty(lessonData)">
        <el-timeline-item v-for="(item, index) in lessonData" placement="top" :key="index" :timestamp="item.timeData">
          <span class="iconfont" slot="dot">&#xe61d;</span>
          <div class="lesson-detail">
            <h4 class="bold">{{ item.sysub_name }}</h4>
            <div class="teacher">
              <el-avatar :size="56" :src="formatfile(item.teuse_image)">
                <img src="@assets/images/empty_avatar.png" />
              </el-avatar>
              <div class="teacher-info">
                <p class="bold">{{ item.teuse_name }}</p>
                <p>授课老师</p>
              </div>
              <el-tag :type="(item.is_lessons == 1 && 'danger') ||
          (item.is_lessons == 2 && 'success') ||
          'info'
          ">
                {{
          (item.is_lessons == 1 && "待开始") ||
          (item.is_lessons == 2 && "已开始") ||
          "已结束"
        }}
              </el-tag>
            </div>

            <ul class="prepare-box">
              <!-- 课件 -->
              <li class="prepare-item">
                <h5 class="bold">课件</h5>
                <div class="prepare-data courseware" :class="{ 'no-data': $isEmpty(item.coursewareData) }">
                  <template v-if="!$isEmpty(item.coursewareData)">
                    <p class="iconfont-color" v-html="fileType(item.coursewareData.teles_cou_type)"></p>
                    <div class="courseware-detail">
                      <p class="title line-text--1st">
                        {{ item.coursewareData.teles_cou_title }}
                      </p>
                      <p class="edition">
                        {{ item.sccla_grade_name }}：{{ item.sysub_name }}
                      </p>
                      <p class="time">
                        时间：{{ item.coursewareData.create_time }}
                      </p>
                      <p class="download">
                        <el-button type="custom_primary" size="mini" v-loading="item.coursewareData.btnload"
                          :disabled="item.coursewareData.btnload" @click.stop="
          handleDownload(
            item.coursewareData.teles_cou_url,
            index
          )
          ">
                          下载
                        </el-button>
                      </p>
                    </div>
                  </template>
                  <div class="no-data--empty" v-if="$isEmpty(item.coursewareData)">
                    <img src="@assets/images/no-data3.png" alt="" />
                    <p>暂无课件信息哦~</p>
                  </div>
                </div>
              </li>
              <!-- 知识点 -->
              <li class="prepare-item">
                <h5 class="bold">知识点</h5>
                <div class="prepare-data knowledge" :class="{ 'no-data': $isEmpty(item.knowledgeData) }">
                  <ul class="knowledge-list" v-if="!$isEmpty(item.knowledgeData)">
                    <li v-for="(point, p_index) in item.knowledgeData" :key="point.teles_kno_id">
                      <p class="title line-text--1st">
                        {{ p_index + 1 }}. {{ point.teles_kno_title }}
                      </p>
                      <span @click="showDialog(point, 'knowledge')">详情&gt;</span>
                    </li>
                  </ul>
                  <div class="no-data--empty" v-if="$isEmpty(item.knowledgeData)">
                    <img src="@assets/images/no-data3.png" alt="" />
                    <p>暂无相关知识点哦~</p>
                  </div>
                </div>
              </li>
              <!-- 作业 -->
              <li class="prepare-item">
                <h5 class="bold">作业</h5>
                <div class="prepare-data homework">
                  <template v-if="!$isEmpty(item.homeworkData)">
                    <div class="homework-title">
                      <p>{{ item.homeworkData.teles_hom_title }}</p>
                      <span @click="getHomework(item.homeworkData.teles_hom_id)">详情&gt;</span>
                    </div>
                    <div class="homework-desc"
                      v-html="item.homeworkData.teles_hom_remark.replace(/\n|\r\n/g, '<br>').replace(/ /g, '&nbsp')">
                    </div>
                  </template>
                  <div class="no-data--empty" v-if="$isEmpty(item.homeworkData)">
                    <img src="@assets/images/no-data3.png" alt="" />
                    <p>暂无作业信息哦~</p>
                  </div>
                </div>
              </li>
              <!-- 课堂练习 -->
              <li class="prepare-item">
                <h5 class="bold">课堂练习</h5>
                <div class="prepare-data practice" :class="{ 'no-data': $isEmpty(item.practiceData) }">
                  <ul class="practice-list">
                    <li v-for="(practice, p_index) in item.practiceData" :key="practice.teles_pra_id">
                      <div class="title">
                        {{ p_index + 1 }}.
                        <el-image style="width: 46px;height: 32px;margin:0 5px;" v-if="practice.teles_pra_title_image"
                          fit="cover" :src="formatfile(practice.teles_pra_title_image)"
                          :preview-src-list="[formatfile(practice.teles_pra_title_image),]" />
                        <p class="line-text--1st">
                          <span class="richflexcenter" style="height: 38px;" v-html="practice.teles_pra_title"></span>
                        </p>
                      </div>
                      <span @click="getPractice(practice.teles_pra_id)">详情&gt;</span>
                    </li>
                  </ul>
                  <div class="no-data--empty" v-if="$isEmpty(item.practiceData)">
                    <img src="@assets/images/no-data3.png" alt="" />
                    <p>暂无课堂练习信息哦~</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div class="no-data--empty" v-if="$isEmpty(lessonData)">
        <img src="@assets/images/no-data3.png" alt="" />
        <p>暂无当天课程信息哦~</p>
      </div>
    </div>
    <!-- 知识点 -->
    <el-dialog :visible.sync="knowledgeDialog" width="554px" @close="hideDialog('knowledge')" title="知识点详情">
      <div class="dialog-knowledge" v-if="!$isEmpty(dialogData) && knowledgeDialog">
        <p class="dialog-knowledge--title">{{ dialogData.teles_kno_title }}</p>
        <p class="dialog-knowledge--content">
          {{ dialogData.teles_kno_content }}
        </p>
      </div>
    </el-dialog>
    <!-- 作业 -->
    <el-dialog :visible.sync="homeworkDialog" width="660px" title="作业详情" @close="hideDialog('homework')">
      <div class="dialog-question detail">
        <div class="dialog-scroll">
          <div class="dialog-question--head">
            <p class="dialog-question--title">
              标题：{{ dialogData.teles_hom_title }}
            </p>
            <p class="dialog-question--desc">
              <span>描述：</span>
              <span v-html="dialogData.teles_hom_remark"></span>
            </p>
          </div>
          <ul class="dialog-question-list">
            <li class="question-item" :class="{ combination: item.teque_ismakeup == 10 }"
              v-for="(item, index) in dialogData.question" :key="item.teque_id">
              <span class="question--num">{{ (index + 1 > 9 && index + 1) || `0${index + 1} ` }}.</span>
              <span class="question--type">{{ item.syque_typ_name }}</span>
              <div class="question--data">
                <h5 class="bold">
                  <!-- <span>{{ item.teque_title }}</span> -->

                  <span class="richinline" v-html="item.teque_title"></span>


                  <el-image style="width: 60px; height: 38px" v-if="item.teque_title_image"
                    :src="formatfile(item.teque_title_image)" fit="cover"
                    :preview-src-list="[formatfile(item.teque_title_image)]" />
                </h5>
                <!-- 组合题 -->
                <template v-if="item.teque_ismakeup == 10">
                  <p class="combination--title">{{ item.teque_describe }}</p>
                  <ol class="combination--subtopic">
                    <li class="subtopic-item" v-for="(citem, cindex) in item.children_question" :key="citem.teque_id">
                      <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                      <div class="subtopic--data">
                        <p class="subtopic--title">
                          <span v-html="citem.teque_title"></span>
                          <el-image style="width: 60px; height: 38px" v-if="citem.teque_title_image"
                            :src="formatfile(citem.teque_title_image)" fit="cover" :preview-src-list="[
          formatfile(citem.teque_title_image),
        ]" />
                        </p>
                        <dl class="subtopic--options" v-if="citem.syque_typ_id == 1 ||
          citem.syque_typ_id == 2 ||
          citem.syque_typ_id == 5
          ">
                          <dt class="option" v-for="o in citem.question_item" :key="o.teque_ite_id">
                            <span>{{ o.teque_ite_code }}.<em class="richinline" v-html="o.teque_ite_title"></em></span>
                            <el-image style="width: 54px; height: 32px" v-if="o.teque_ite_image"
                              :src="formatfile(o.teque_ite_image)" fit="cover" :preview-src-list="[
          formatfile(o.teque_ite_image),
        ]" />
                          </dt>
                        </dl>
                        <!-- <p class="subtopic--knowledge" v-if="citem.teles_kno_title">{{ citem.teles_kno_title }}</p> -->
                        <div class="subtopic--answer">
                          <p class="answer">
                            答案： <span class="richinline" v-html='citem.sure_answer.join(",")'></span>
                          </p>
                          <p class="analysis" v-if="citem.teque_analysis">
                            <span class="richblock" v-html="citem.teque_analysis"></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ol>
                </template>
                <!-- 其他题型 -->
                <template v-else>
                  <ul class="question--options" v-if="item.syque_typ_id == 1 ||
          item.syque_typ_id == 2 ||
          item.syque_typ_id == 5
          ">
                    <li class="option" v-for="o in item.children_question" :key="o.teque_ite_id">

                      <span class="richflexcenter" v-html="o.teque_ite_code + '.' + o.teque_ite_title"></span>
                      <!-- <span>{{ o.teque_ite_code }}. {{ o.teque_ite_title }}1</span> -->

                      <el-image style="width: 54px; height: 32px" v-if="o.teque_ite_image"
                        :src="formatfile(o.teque_ite_image)" fit="cover"
                        :preview-src-list="[formatfile(o.teque_ite_image)]" />
                    </li>
                  </ul>
                  <!-- <p class="question--knowledge" v-if="item.teles_kno_title">{{ item.teles_kno_title }}</p> -->
                  <div class="question--answer">
                    <p class="answer">答案：<span class="richinline" v-html="item.sure_answer.join(',')"></span></p>
                    <p class="analysis" v-if="item.teque_analysis">
                      <span class="richblock" v-html="item.teque_analysis"></span>
                    </p>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
    <!-- 课堂练习 -->
    <!-- <el-dialog :visible.sync="practiceDialog" width="660px" title="课堂练习详情" @close="hideDialog('practice')">
            <div class="dialog-question practice" v-if="!$isEmpty(dialogData) && practiceDialog">
                <div class="dialog-question--practice">
                    <span class="question--type">{{ dialogData.syque_typ_name }}</span>
                    <div class="question--data">
                        <h5 class="bold">
                            <span>{{ dialogData.teles_pra_title }}</span>
                            <el-image style="width: 60px; height: 38px" v-if="dialogData.teles_pra_title_image"
                                :src="formatfile(dialogData.teles_pra_title_image)" fit="cover"
                                :preview-src-list="[formatfile(dialogData.teles_pra_title_image)]" />
                        </h5>
                        <ul class="question--options">
                            <li class="option" v-for="item in dialogData.item" :key="item.teles_pra_ite_id">
                                <span>{{ item.teles_pra_ite_code }}. {{ item.teles_pra_ite_title }}</span>
                                <el-image v-if="item.teles_pra_ite_image" style="width: 54px; height: 32px"
                                    :src="formatfile(item.teles_pra_ite_image)" fit="cover"
                                    :preview-src-list="[formatfile(item.teles_pra_ite_image)]" />
                            </li>
                        </ul>
                        <p class="question--knowledge" v-if="dialogData.teles_kno_title">
                            {{ dialogData.teles_kno_title }}
                        </p>
                        <div class="question--answer">
                            <p class="answer" v-if="dialogData.answer">答案：{{ dialogData.answer }}</p>
                            <p class="analysis" v-if="dialogData.teles_pra_analysis">{{ dialogData.teles_pra_analysis }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog> -->
    <el-dialog :visible.sync="practiceDialog" width="660px" title="课堂练习详情" @close="hideDialog('practice')">
      <div class="dialog-question detail">
        <div class="dialog-question--practise dialog-scroll">
          <span class="question--type">{{ dialogData.syque_typ_name }}</span>
          <div class="question--data">
            <h5 class="bold">
              <!-- <span>{{ dialogData.teles_pra_title }}</span> -->

              <span class="richinline" v-html="dialogData.teles_pra_title"></span>
              <el-image style="width: 60px; height: 38px" v-if="dialogData.teles_pra_title_image"
                :src="formatfile(dialogData.teles_pra_title_image)" fit="cover" :preview-src-list="[
          formatfile(dialogData.teles_pra_title_image),
        ]" />
            </h5>
            <!-- 组合题 -->
            <template v-if="dialogData.teles_pra_ismakeup == 10">
              <p class="combination--title">
                {{ dialogData.teles_pra_describe }}
              </p>
              <ol class="combination--subtopic">
                <li class="subtopic-item" v-for="(citem, cindex) in dialogData.option_arr" :key="citem.teles_pra_id">
                  <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                  <div class="subtopic--data">
                    <p class="subtopic--title">
                      <!-- <span>{{ citem.teles_pra_title }}</span> -->
                      <span class="richinline" v-html="citem.teles_pra_title"></span>
                      <el-image style="width: 60px; height: 38px" v-if="citem.teles_pra_title_image"
                        :src="formatfile(citem.teles_pra_title_image)" fit="cover" :preview-src-list="[
          formatfile(citem.teles_pra_title_image),
        ]" />
                    </p>
                    <dl class="subtopic--options" v-if="citem.syque_typ_id == 1 ||
          citem.syque_typ_id == 2 ||
          citem.syque_typ_id == 5
          ">
                      <dt class="option" v-for="o in citem.question_item" :key="o.teles_pra_ite_id">
                        <!-- <span>{{ o.teles_pra_ite_code }}.
                          {{ o.teles_pra_ite_title }}</span> -->
                          <span class="richflexcenter" v-html="o.teles_pra_ite_code + '.' + o.teles_pra_ite_title"></span>
                        <el-image style="width: 54px; height: 32px" v-if="o.teles_pra_ite_image"
                          :src="formatfile(o.teles_pra_ite_image)" fit="cover" :preview-src-list="[
          formatfile(o.teles_pra_ite_image),
        ]" />
                      </dt>
                    </dl>
                    <p class="subtopic--knowledge" v-if="citem.teles_kno_title">
                      {{ citem.teles_kno_title }}
                    </p>
                    <div class="subtopic--answer" v-if="!$isEmpty(citem.sure_answer) || citem.teles_pra_analysis
          ">
                      <p class="answer" v-if="!$isEmpty(citem.sure_answer)">
                        答案：
                        <!-- {{ citem.sure_answer.join(",") }} -->
                        <span class="richinline" v-html='citem.sure_answer.join(",")'></span>
                      </p>
                      <p class="analysis" v-if="citem.teles_pra_analysis">
                        <!-- {{ citem.teles_pra_analysis }} -->
                        <span class='richblock' v-html="citem.teles_pra_analysis"></span>
                      </p>
                    </div>
                  </div>
                </li>
              </ol>
            </template>
            <!-- 其他题型 -->
            <template v-else-if="dialogData.option_arr">
              <ul class="question--options" v-if="dialogData.syque_typ_id == 1 ||
          dialogData.syque_typ_id == 2 ||
          dialogData.syque_typ_id == 5
          ">
                <li class="option" v-for="o in dialogData.option_arr[0].question_item" :key="o.teles_pra_ite_id">

                  <!-- <span>{{ o.teles_pra_ite_code }}.{{ o.teles_pra_ite_title }}</span> -->
                  <span class='richflexcenter' v-html='o.teles_pra_ite_code + "." + o.teles_pra_ite_title'></span>

                  <!-- <span class="richflex" v-html="o.teles_pra_ite_code + '.' + o.teles_pra_ite_title">
                  </span> -->

                  <el-image style="width: 54px; height: 32px" v-if="o.teles_pra_ite_image"
                    :src="formatfile(o.teles_pra_ite_image)" fit="cover"
                    :preview-src-list="[formatfile(o.teles_pra_ite_image)]" />
                </li>
              </ul>

              <p class="question--knowledge" v-if="dialogData.option_arr[0].teles_kno_title">
                {{ dialogData.option_arr[0].teles_kno_title }}
              </p>
              <div class="question--answer">
                <p class="answer" v-if="!$isEmpty(dialogData.option_arr[0].sure_answer)">
                  答案：
                  <!-- {{ dialogData.option_arr[0].sure_answer.join(",") }} -->
                  <span class="richinline" v-html='dialogData.option_arr[0].sure_answer.join(",")'></span>
                </p>
                <p class="analysis" v-if="dialogData.option_arr[0].teles_pra_analysis">
                  <!-- {{ dialogData.option_arr[0].teles_pra_analysis }} -->
                  <span class="richblock" v-html="dialogData.option_arr[0].teles_pra_analysis"></span>
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  $getLessonInfo,
  $getLessonPractice,
  $getLessonHomework,
} from "@api/lesson";
import { $downloadFile } from "@api/common";
import { formatFile, formatFileType } from "@utils";
export default {
  name: "lesson_information",
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
  },
  data() {
    return {
      routeParams: {}, // 路由携带参数
      lessonData: [], // 课程信息数据
      knowledgeDialog: false, // 知识点
      homeworkDialog: false, // 作业
      practiceDialog: false, // 课堂练习
      dialogData: {}, // 弹窗填充内容
    };
  },
  created() {
    let { id, class: className, day } = this.$route.params;
    this.routeParams = {
      className,
      gradeId: id.split(",")[0],
      classId: id.split(",")[1],
      day: new Date(day).getTime(),
    };
    this.getLessonInfo();
  },
  methods: {
    /** 获取课程数据 */
    async getLessonInfo() {
      let { day, classId } = this.routeParams;
      let { data } = await $getLessonInfo(day / 1000, classId);
      data.map((item) => {
        if (!this.$isEmpty(item.coursewareData)) {
          item.coursewareData.btnload = false;
        }
      });
      this.lessonData = [...data];
      this.$forceUpdate();
    },
    /** 下载课件 */
    async handleDownload(url, index) {
      this.lessonData[index].coursewareData.btnload = true;
      let { data } = await $downloadFile(url);
      this.lessonData[index].coursewareData.btnload = false;
      if (data.url) {
        this.$fileDownload(data.url);
      }
    },
    /**
     * 获取作业详情
     * @param {number} id 作业id1
     */
    async getHomework(id) {
      let { data } = await $getLessonHomework(id);
      this.showDialog(data, "homework");
    },
    /**
     * 获取课堂练习详情
     * @param {number} id 课堂练习id1
     */
    async getPractice(id) {
      let { data } = await $getLessonPractice(id);
      //   let answer = [];
      //   data.item.map(
      //     (i) =>
      //       i.teles_pra_ite_isanswer == 10 && answer.push(i.teles_pra_ite_code)
      //   );
      //   data = { ...data, answer: answer.join(",") };
      this.showDialog(data, "practice");
    },
    /**
     * 打开课程信息弹窗
     * @param {object} data 弹窗内容数据
     * @param {string} type 弹窗类型
     */
    showDialog(data, type) {
      this.dialogData = data;
      this[`${type}Dialog`] = true;
    },
    /**
     * 关闭课程信息弹窗
     * @param {string} type 弹窗类型
     */
    hideDialog(type) {
      this.dialogData = {};
      this[`${type}Dialog`] = false;
    },
  },
};
</script>
