/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: wang hai yang
 * @LastEditTime: 2023-05-11 19:54:44
 * @Descripttion: 课程管理模块接口
 */
import HTTP from "@utils/http";

const PREFIX = "/school/schclassschedule";

/* 课程信息 */

/*******
 * @Descripttion: 获取某日课程信息
 * @Author: Dyf
 * @param {number} date 日期时间戳
 * @param {number} sccla_id 班级id
 * @return {*}
 */
export async function $getLessonInfo(date, sccla_id) {
  try {
    return await HTTP.post(
      `${PREFIX}/get_day_classedu`,
      { date, sccla_id },
      { load: true, loadEl: ".inner-data" }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取课程信息 - 课堂练习详情
 * @Author: Dyf
 * @param {number} tetea_pra_id 课堂练习id
 * @return {*}
 */
export async function $getLessonPractice(tetea_pra_id) {
  try {
    return await HTTP.post(`${PREFIX}/get_practice_info`, { tetea_pra_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取课程信息 - 作业详情
 * @Author: Dyf
 * @param {number} tetea_hom_id 作业id
 * @return {*}
 */
export async function $getLessonHomework(tetea_hom_id) {
  try {
    return await HTTP.post(`${PREFIX}/get_homeWork_info`, { tetea_hom_id });
  } catch (error) {
    return error;
  }
}

/* 配置课程表 */

/*******
 * @Descripttion: 获取课程表信息
 * @Author: Dyf
 * @param {Number} params.sccla_sch_grade 年级id
 * @param {Number} params.sccla_id 班级id
 * @param {Number} params.sccla_sch_semester  学期：1=>上~  2=>下~
 * @return {*}
 */
export async function $timeTableInfo(params) {
  try {
    return await HTTP.post(`${PREFIX}/info`, params, {
      load: true,
      loadEl: ".school-timetable--inner",
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表内容添加
 * @Author: Dyf
 * @param {Object} params 课程表单信息
 * @return {*}
 */
export async function $timeTableAdd(params) {
  try {
    return await HTTP.post(`${PREFIX}/add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表内容编辑
 * @Author: Dyf
 * @param {Object} params 课程表单信息
 * @return {*}
 */
export async function $timeTableEdit(params) {
  try {
    return await HTTP.post(`${PREFIX}/edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表内容清除
 * @Author: Dyf
 * @param {Number} sccla_sch_id 课程内容id
 * @return {*}
 */
export async function $timeTableColDel(sccla_sch_id) {
  try {
    return await HTTP.post(`${PREFIX}/delete`, { sccla_sch_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表整行内容清除
 * @Author: Dyf
 * @param {Number} sccla_sch_tim_id 课程表时间id
 * @return {*}
 */
export async function $timeTableRowlDel(params) {
  try {
    return await HTTP.post(`${PREFIX}/del_row`,  params );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下载课表模板
 * @Author: Dyf
 * @return {*}
 */
export async function $downloadTemplate() {
  try {
    return await HTTP.post(`${PREFIX}time/importtemplate`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 导入课程数据
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $importTimetable(params) {
  try {
    return await HTTP.post(`${PREFIX}time/import`, params);
  } catch (error) {
    return error;
  }
}
